import type { NextPage } from "next";
import Link from "next/link";

const Custom404: NextPage = () => {
  return (
    <div className="h-screen flex items-center justify-center text-[#14F195]">
      <div className="font-mono text-center">
        <p className="text-xl mb-4">/// 404</p>
        <h2 className="text-2xl mb-4">
          THE QUESTION IS: ARE YOU SURE<br />
          YOU'RE IN THE RIGHT PLACE?
        </h2>
        <Link
            href="/"
            className="hover:cursor-pointer hover:scale-105 text-[#14F195] font-medium whitespace-nowrap text-center items-center justify-center flex py-2 ease-in duration-100"
        >
              [ START OVER ]
        </Link>
      </div>
    </div>
  );
};

export default Custom404;